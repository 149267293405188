<template>
  <div class="d-flex align-items-center float-left">
    <h4 class="content-header-title mb-0">
      <feather-icon
        v-if="$route.meta.icon"
        :icon="$route.meta.icon"
        size="16"
      />
      {{ $te(`routes.${$route.name}`)? t(`routes.${$route.name}`): $route.name }}
    </h4>
    <div
      v-if="$route.meta.breadcrumb"
      class="breadcrumb-wrapper"
    >
      <b-breadcrumb>
        <b-breadcrumb-item to="/">
          <feather-icon
            icon="HomeIcon"
            size="16"
            class="align-text-top"
          />
        </b-breadcrumb-item>
        <b-breadcrumb-item
          v-for="item in $route.meta.breadcrumb"
          :key="item.name"
          :active="item.active"
          :to="item.to"
        >
          <span v-if="item.active && item.isUseId">
            {{ $route.params.id }}
          </span>
          <span v-else>
            {{ $te(`routes.${item.name}`)? t(`routes.${item.name}`): item.name }}
          </span>
        </b-breadcrumb-item>
      </b-breadcrumb>
    </div>
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import { useUtils as useI18nUtils } from '@core/libs/i18n'

export default {
  directives: {
    Ripple,
  },
  components: {},
  setup() {
    const { t } = useI18nUtils()
    return {
      t,
    }
  },
}
</script>
