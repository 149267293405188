<template>
  <li
    class="nav-item active warning"
  >
    <b-link
      class="d-flex align-items-center"
    >
      <feather-icon icon="BoxIcon" />
      <span
        class="menu-title text-truncate"
      >
        Sandbox
      </span>
      <feather-icon
        icon="RepeatIcon"
        style="
        position: absolute;
        top: 10;
        right: 10;
        "
      />
    </b-link>
  </li>
</template>

<script>

export default {

}
</script>
